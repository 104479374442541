<template>
	<div class="ass_main">
		
        <div class="ass_content">
            <div class="ac_title">
                <span>快捷设置</span>
                 <div class="action_txt" @click="toAddWarning">
                    <span>手动设置</span>
                </div>
            </div>
            <div class="ac_list">
                <template v-for="item in warningList">
                    <div class="acl_div" :key="item.id" @click="setWarning(item)" :class="{selectacl_div:nowItem.id==item.id}">
                        <span>{{item.name}}</span>
                    </div>
                </template>
           
            </div>
            <div class="action_div">
               
            </div>
        </div>

	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import until from '@/api/until.js'
import tool_api from '@/api/teaching_studio_tool.js'

export default {
	name: 'Home',
	data() {
		return {
			lastData:{},
			unit:"",
			warningList:[],
			deviceName:'',
			property_name:'',
            nowItem:{},
            salt:"",
		}
	},
	components: {

	},
	mounted() {
		document.title="新增报警方式-快捷新增"
        this.getWarningList();
        this.getSalt();
	},
	methods: {
        //获取表单验证码
		async getSalt() {
			let computer_code = until.getCookie("guid").substring(0, 6)
			let data = await tool_api.ToolForm_saltGet({ computer_code });
			if (data.errcode == 0) {

				this.salt = data.other;
			}
		},

		
		getTime(time){
			return until.formatDateHour(time*1000)
		},	
		//获取更多数据
		async getWarningList(){
			let app_id=this.$root.app_id;
            let product_key=""
            let property_key=this.$route.query.property_key
            let name=""
			let data=await studio_api.Teaching_studio_apiWarning_range_quickListGet({app_id,product_key,property_key,name});
			if(data.errcode==0){
                this.warningList=data.other.list;
             
				
			}
		},
        async setWarning(item){
            let app_id=this.$root.app_id;
            let quick_id=item.id;
            let property_id=this.$route.query.property_id
            let computer_code=until.getCookie("guid").substring(0, 6)
            let salt=this.salt
            this.nowItem=item
            this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});
            let data=await studio_api.Teaching_studio_apiWarning_range_quickToDevice_property_warningPut({app_id,quick_id,property_id,computer_code,salt});
            if(data.errcode==0){
                this.$toast("设置成功");
                window.history.go(-1)

            }
        },

        toAddWarning(){
			this.$router.push("/addwarning?property_id="+this.$route.query.property_id+"&property_key="+this.$route.query.property_key+"&property_name="+this.$route.query.property_name+"&back_index=2");

		},
	
	}
}
</script>
<style scoped>
.ass_main{
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
    overflow: hidden;

	box-sizing: border-box;
    padding: 0 30px;

}
.ass_content{
    width: 100%;
    height: calc(100vh - 62px);
    background: #ffffff;
    margin-top:24px;
    border-radius: 20px 20px 20px 20px;
    display: inline-block;
    position: relative;
    
}
.ac_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding:0 40px;
    box-sizing: border-box;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    line-height: 40px;
}
.ac_list{
    width: 100%;
    display: inline-block;
    margin-top: 24px;
    padding: 0 40px;
    box-sizing: border-box;
}
.acl_div{
    width: 100%;
    height: 88px;
    background: #F6F6F6;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-top: 40px;
}
.acl_div:first-child{
    margin-top: 0px;
}
.action_div{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 80px;
}
.action_txt{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #FFA14E;
    font-weight: bold;
}
.selectacl_div{
    background: #02E4AE;
    color: #ffffff;
}
</style>

